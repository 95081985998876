@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter\ Desktop/Inter-Medium.otf") format("truetype");
}

// -----Utilities--------
.flex-disp{
  display: flex;
}
.justify-center{
  justify-content: center;
  align-items: center;
}
.text-white{
  color: white;
  font-size: 14px;
}

// -----Dialog Modal-------
.dialog-modal{
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.dialog-child{
  flex-direction: column !important;
  background: linear-gradient(black, rgba(0, 0, 0, 0.73));
  padding: 1rem 1rem 2rem 1rem ;
  border-radius: 12px;
  max-width: 90%;
}

.modal-heading{
  font-size: 18px;
  color: white;
}

.modal-btns{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 2rem;
}
.modal-btns>button{
  padding: 5px 12px;
  min-width: 70px;
  border: none;
  border-radius: 4px;
  background-color: #00a99d;
  color: white;
  cursor: pointer;
  font-size: 14px;
  &:hover{
    opacity: 0.8;
  }
}
.gray-pay{
  background-color: gray !important;
  cursor: not-allowed !important;
  opacity: 0.8;
}


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter";
}

body {
  background: #0f0e0e;
  overflow-x: hidden;
}

.payment-root {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.home{
  position: relative;
}

.app {
  background: linear-gradient(#040e27, rgba(4, 14, 39, 0.3));
  padding: 2rem;
}

.payment-container {
  padding: 1rem !important;
  flex-grow: 10 !important;
}

// Material UI CSS
.MuiSvgIcon-root,
.MuiFormLabel-root,
.MuiInputBase-root {
  fill: rgba(255, 255, 2555, 0.81) !important;
  color: rgba(255, 255, 2555, 0.81) !important;
  .Mui-disabled {
    -webkit-text-fill-color: #ffffff !important;
  }
}
.MuiInputBase-input.Mui-disabled {
  color: rgba(255, 255, 255, 0.81) !important;
}
.MuiSvgIcon-root {
  margin-right: 10px !important;
}
.MuiFormLabel-root {
  font-size: 16px !important;
}
.MuiButton-root {
  padding: 12px 16px !important;
  background: #00a99d !important;
  text-transform: none !important;
}
.MuiButton-root.Mui-disabled {
  background: rgba(0, 169, 157, 0.8) !important;
  color: rgba(255, 255, 255, 0.81) !important;
  cursor: not-allowed !important;
}
.MuiButtonBase-root.MuiIconButton-root {
  background: transparent !important;
  button {
    padding: 0px !important;
  }
}

.MuiPaper-root.MuiPopover-paper {
  background: #151823 !important;
  ul > li:hover {
    background: rgba(0, 0, 0, 0.5) !important;
  }
}

.MuiMenuItem-root.Mui-selected {
  background: rgb(29, 44, 72) !important;
}
.MuiMenuItem-root {
  background: #151823 !important;
  color: rgba(255, 255, 255, 0.81) !important;
  max-height: calc(36px * 3) !important;
}

// Due and Paid badges CSS
.payment-badge {
  position: absolute !important;
  right: 1em !important;
  top: 10%;
  border-radius: 4px;
  padding: 0px 0.5em;
  display: flex;
  align-self: center;
  span {
    font-size: 12px;
  }
  &.success {
    border: 1px solid #00a99d;
    background: #00a99d;
    span {
      color: white;
    }
  }
  &.due {
    border: 1px solid red;
    background: red;
    span {
      color: white;
    }
  }
}

/* loader text dot CSS */
.loader-text {
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: baseline;
  p {
    font-weight: 300;
    margin-right: 10px;
  }
  span {
    width: 8px;
    height: 8px;
    background: #ffffff;
    margin: 0px 4px;
    border-radius: 4px;

    animation: loading 1s ease infinite;
    @for $i from 1 through 3 {
      &:nth-child(#{$i + 1}) {
        animation-delay: #{$i * 0.1}s;
      }
    }
    @keyframes loading {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(10px);
      }
    }
  }
}

// Project Data Section CSS
.project-data {
  border: "2px solid rgba(0, 169, 157, 0.8)";
  width: "100px";
  height: "100px";
  border-radius: "50%";
  margin: "0 auto";
}
.project-poster {
  width: 20% !important;
  height: 20% !important;
  margin: 0px auto !important;
}

.project-status {
  display: flex;
}

// responsiveness css
.payment-description {
  margin: 0px auto;
  color: rgba(255, 255, 255, 0.81);
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}

// Footer CSS

.footer-wrapper {
  // margin-top: 30%;
  padding-bottom: 10px;

  .MuiGrid-root {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    @media screen and (max-width: 468px) {
      flex-direction: column !important;
      align-items: center !important;
    }
  }
  @media screen and (max-width: 468px) {
    padding-bottom: 0px;
  }
}

.footer-link {
  overflow-x: hidden;
  &:hover > .footer-fill {
    transform: translateX(0);
  }
  .footer-fill {
    height: 2px;
    background: white;
    transition: transform 0.2s ease-in-out;
    transform: translateX(-100%);
  }
}

// Success page CSS

.success {
  justify-content: center;
  align-items: center;
  // height: 100vh;
  // min-width: 100%;
}
.success-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-width: 100%;
}

.success-card {
  padding: 1rem;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  background-color: transparent !important;
  & > h4 {
    font-size: 30px !important;
    margin: 1em 0px;
    color: #ffffff !important;
  }
  & > p {
    color: #ffffff !important;
  }
}

.changeProgressbarColor {
  & > span > span {
    background-color: green !important;
  }
}

.Toastify__toast-theme--light {
  background: var(--toastify-color-dark) !important;
  color: var(--toastify-text-color-dark) !important;
}

// Download Btn css
@media screen and (max-width: 768px) {
  .download-btn-wrapper {
    display: flex !important;
    justify-content: space-between;
    & > button {
      width: 48% !important;
      @media screen and (max-width: 480px) {
        width: 100% !important;
      }
    }
  }
}

// coupon container
.coupon-container {
  display: flex;
  &>button{
    width: 23% !important;
    @media screen and (max-width: 480px) {
      width: 38% !important;
    }
  }
}

// success page

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #00a99d;
  stroke-miterlimit: 10;
  // margin: 10% auto;
  box-shadow: inset 0px 0px 0px #00a99d;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 5px #00a99d;
    }
  }
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #00a99d;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    @keyframes stroke {
      100% {
        stroke-dashoffset: 0;
      }
    }
  }
}

.currency-box{
  display: flex;
  gap: 10px;
  &>button{
    width: 80%;
    @media screen and (max-width: 480px) {
      width: 70%;
    }
  }
  &>div{
    width: 20%;
    background-color: #00a99d;
    color: white;
    @media screen and (max-width: 480px) {
      width: 30%;
    }
  }
  &> .Mui-focused .MuiOutlinedInput-notchedOutline{
    border: none !important;
  }
}



// ##########################-------For the invoice--------##################################### 
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&family=Zilla+Slab&display=swap');

.invoice{
  width: 75%;
  margin: 0 auto;
}
.invoice>div{
  font-family: 'Nunito', sans-serif !important;
font-family: 'Zilla Slab', serif !important;
font-weight:300 !important;
}

.main {
    margin: "10px auto";
    padding: 2rem;
    font-family: 'Nunito', sans-serif;
    /* page-break-after: always; */
}

.header {
    display: -webkit-box;
}

.info-header {
    width: 33%;
}

.info-header>h1 {
    margin-bottom: 20px;
}
.text-header{
    width: 33%;
    text-align: center;
    color: #00a99d;
    font-size: 40px;
    font-weight: 600;
    height: fit-content;
    margin-top: auto;
    line-height: 0;
}
.logo-header {
    width: 33%;
    text-align: right;
}

.logo-header>img {
    width: 8rem;
}

/* ----utilities----- */
.color-gray {
    color: gray;
}

.color-purple {
    color: #00a99d;
}

.bg-purple {
    background: #f8f8f8;
}
.main{
  background-color: white;
}
.main>h1,
h2,
h4,
h3 {
    margin: 0;
}

.flex {
    display: -webkit-box;
}

/* -------cards------------ */
.card-container {
    display: flex;
    width: 100%;
    gap: 1.5%;
    margin-top: 3rem;
    font-size: 15px;
}
.card-container div{
  width: 50%;
}
// .card-container>:nth-child(1) {
//      margin-right: 4%;
// }

.card {
    border-radius: 12px;
    padding: 1rem 1rem;
    width: 39.5%;
}

.card-container>.card.bg-purple>h4 {
    margin: 6px 0;
}

/* -----------table------- */
.table-container {
    margin-top: 1rem;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

/* ********header******** */
.table-header {
    display: -webkit-box;
    background: #00a99d;
    color: white;
    padding: 10px 0px;
    width: 100%;
}


.table-header>:nth-child(1) {
    padding: 0 9px;
    width: 8%;
}

.table-header>:nth-child(2) {
    padding: 0 9px;
    width: 40%;
}

.table-header>:nth-child(3) {
    text-align: center;
    padding: 0 9px;
    width: 14%;
}

.table-header>:nth-child(4) {
    text-align: center;
    padding: 0 9px;
    width: 12%;
}

.table-header>:nth-child(5) {
    text-align: center;
    padding: 0 9px;
    width: 12%;
}

/* ********row******** */
.table-row {
    display: -webkit-box;
    background: #f3f2f2;
    color: black;
    width: 100%;
}

.table-row>div>span {
    padding: 8px 0px;
    display: block;
}

.table-row>:nth-child(1) {
    /* text-align: center; */
    padding: 0 9px;
    width: 8%;
    // border-right: 0.3px solid black;
}

.table-row>:nth-child(2) {
    padding: 0 9px;
    width: 40%;
}

.table-row>:nth-child(3) {
    text-align: center;
    padding: 0 9px;
    width: 14%;
}

.table-row>:nth-child(4) {
    text-align: center;
    padding: 0 9px;
    width: 12%;
}

.table-row>:nth-child(5) {
    text-align: center;
    padding: 0 9px;
    width: 12%;
}

/* ------stats section------- */
.stats-sec {
    display: -webkit-box;
    margin-top: 2.5rem;
    width: 100%;
}

/* ********* Left col **********  */
.stats-sec>.stats-sec-col1 {
    width: 52%;
    padding-right: 3%;
}

/* ---------main-card---------- */
.main-card-container {
    display: -webkit-box;
}

.main-card {
    width: 60%;
    border-radius: 12px;
    padding: 1rem 1rem;
    height: fit-content;
    font-size: 13px;
}

.main-card.bg-purple>.color-purple {
    margin-bottom: 4px;
    font-weight: 500;
}

.main-card.bg-purple>div {
    width: 100%;
    display: -webkit-box;
}

.main-card.bg-purple>div>span {
    display: inline-block;
    width: 50%;
}

.main-card.bg-purple>div>:nth-child(1) {
    font-weight: 600;
}
.main-card.bg-purple>div>:nth-child(2) {
  font-weight: 300;
}

.main-card.bg-purple>div>:nth-child(1) {
    color: #211743;
}

.bank-detail {
    display: -webkit-box;
}

.bank-detail>div {
    width: 50%;
}

/* --------qr container------- */
.qr-cont {
    overflow: hidden;
    width: 7rem;
    margin-left: 10px;
}

.qr-cont>div {
    margin-bottom: 4px;
    color: gray;
    font-size: 10px;
}

.qr-cont>img {
    width: 100%;
    height: auto;
}

/* -----------terms&conditions-------- */
.terms{
  font-size: 17px;
}
.terms-conditions {
    margin-top: 4rem;
    padding: 0 10px;

}

/* ********* Right col **********  */
.stats-sec>.stats-sec-col2 {
    width: 45%;
}

.amount-container {
    display: -webkit-box;
    margin-top: 5px;
    -webkit-box-pack: center;
    align-items: center;
}

.amount-container>.f-left {
    width: 60%;
}

.amount-container>.f-right {
    width: 40%;
    text-align: right;
}

.amount-total {
    /* border-top: 0.5px sold black; */
    border-top: 1px solid black;
    padding: 10px 0;
}

.amount-total>.f-right {
    font-weight: 700;
}

.invoice-in-words {
    margin-top: 0.5rem;
}

.invoice-in-words>span {
    color: gray;
    font-size: small;
}

.invoice-in-words>div {
    color: black;
    font-size: large;
}

.early-pay {
    border-top: 1px solid black;
}

.early-pay>.stat-row-red {
    display: -webkit-box;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.early-pay>.stat-row-red>.col-1 {
    width: 50%;
}

.early-pay>.stat-row-red>.col-2 {
    width: 50%;
    text-align: right;
    color: rgba(245, 13, 5, 0.9);
}

.early-pay>.stat-row-red>.col-1>:nth-child(1) {
    color: rgba(245, 13, 5, 0.9);
    font-size: large;

}

.flat-discount{
  color: #00a99d;
  font-weight: 500;;
}

.early-pay>.stat-row-red>.col-1>:nth-child(2) {
    color: gray;
    font-size: 10px;

}

.early-pay>.stat-row-black {
    margin-top: 10px;
    display: -webkit-box;
}

.early-pay>.stat-row-black>div {
    width: 50%;
}

.early-pay>.stat-row-black>:nth-child(2) {
    text-align: right;
}

.loader-container{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
